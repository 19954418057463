import React from "react";
import PropTypes from "prop-types";
import {Container, Nav, NavItem, NavLink} from "shards-react";
import {NavLink as RouteNavLink} from "react-router-dom";

const SideBar = () => (
    <Container>
        <h5>Welcome, Emilio</h5>
        <div className="nav-wrapper">
            <Nav className="nav--no-borders flex-column">
                <NavItem key="settings">
                    <NavLink tag={RouteNavLink} to="/user/settings">Settings</NavLink>
                </NavItem>
                <NavItem key="logout">
                    <NavLink tag={RouteNavLink} to="/user/settings">Make a donation</NavLink>
                </NavItem>
                <NavItem key="donate">
                    <NavLink tag={RouteNavLink} to="/user/settings">Logout</NavLink>
                </NavItem>
            </Nav>
        </div>
    </Container>
);

SideBar.propTypes = {
    /**
     * Whether to display the navbar, or not.
     */
    noNavbar: PropTypes.bool,
    /**
     * Whether to display the footer, or not.
     */
    noFooter: PropTypes.bool
};

SideBar.defaultProps = {
    noNavbar: false,
    noFooter: false
};

export default SideBar;
