import {SAVE_PERMISSIONS} from "../actions/actionTypes";

export default function permissions(state = {}, action) {
    switch (action.type) {
        case SAVE_PERMISSIONS:
            return Object.assign({}, state, {
                actionType: action.type,
                savePermissionsResult: action.savePermissionsResult
            });

        default:
            return state;
    }
}