import React from "react";
import {Col, Container, Row} from "shards-react";

import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import CakeIcon from '@material-ui/icons/Cake';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

const Memorials = () => (
    <Container fluid>
        <Row>
            <Col sm="12" md={{size: 8, offset: 2}}>
                <div style={{
                    backgroundImage: `url(https://s3.us-east-2.amazonaws.com/magogo-img/60cfb536139ed24216530bb3/74c56565-bcfc-43f7-9b3e-b6382e73a1ff)`,
                    backgroundRepeat: "no-repeat",
                    height: 384,
                    borderRadius: '1.5rem',
                    backgroundPosition: '50%',
                    marginTop: 10
                }}>
                </div>
            </Col>
        </Row>
        <Row>
            <Col sm="12" md={{size: 8, offset: 2}}>
                <div style={{textAlign: "center", marginBottom: 15}}>
                    <img style={{position: "relative", marginTop: -200, borderRadius: 9999}}
                         src="https://s3.us-east-2.amazonaws.com/magogo-img/60cfb536139ed24216530bb3/1deef9d0-2fda-4039-85f9-8736c798167f"/>
                </div>
            </Col>
        </Row>

        <Row>
            <Col sm="12" md={{size: 6, offset: 3}}>
                <div style={{textAlign: "center", marginBottom: 25}}>
                    <h3>Maruja Gonzales Gonzales</h3>
                    <h6>09/04/1951 - 24/06/2020</h6>
                    <h6>Tía Marujita: tú has sido mi Hada Madrina, la de la pomada de la invisibilidad, y por tu Alegría
                        tan firme y constante, indubitable, has logrado convencerme de que, efectivamente, de vez en
                        cuando, este mundo trueca sus lágrimas por risas y contento.</h6>
                    <i>- Escrito originalmente por M.A.D. para su tía Carolina -</i>
                </div>
            </Col>
        </Row>

        <Row>
            <Col sm="12" md={{size: 6, offset: 3}}>
                <VerticalTimeline layout="1-column-left">

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="1951"
                        iconStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                        icon={<CakeIcon/>}>
                        <h5 className="vertical-timeline-element-title">Lunes, 9 de Abril de 1951</h5>
                        <h6 className="vertical-timeline-element-subtitle">Morales, San Martín</h6>
                        <p>
                            Una mañana del 9 de Abril de 1951 nació nuestra querída tía Maruja en el distrito de
                            Morales.
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work" date=""
                        iconStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}} icon={<PhotoCameraIcon/>}>
                        <Container style={{textAlign: "center"}}>
                            <img style={{maxWidth: '90%', maxHeight: 400, borderRadius: 5}}
                                 src="https://s3.us-east-2.amazonaws.com/magogo-img/60cfb536139ed24216530bb3/416062cd-ff74-49c0-9c91-b4e1c0e549a8"/>
                        </Container>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work" date=""
                        iconStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}} icon={<PhotoCameraIcon/>}>

                        <Container style={{textAlign: "center"}}>
                            <img style={{maxWidth: '90%', maxHeight: 400, borderRadius: 5}}
                                 src="https://s3.us-east-2.amazonaws.com/magogo-img/60cfb536139ed24216530bb3/d5ffad9f-ec32-4e28-82f2-2b19587a8558"/>
                        </Container>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        iconStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}} icon={<CameraEnhanceIcon/>}>

                        <Container style={{textAlign: "center"}}>
                            <video style={{maxWidth: '90%', maxHeight: 400, borderRadius: 5}} autoPlay loop muted
                                   playsInline controls>
                                <source
                                    src="https://s3.us-east-2.amazonaws.com/magogo-img/60cfb536139ed24216530bb3/4c2d7261-837f-4d39-ab9a-573856e5ecc4"
                                    type="video/mp4"/>
                            </video>
                        </Container>

                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="24 Junio 2020"
                        iconStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                        icon={<FavoriteIcon/>}>
                        <p>
                            "Marujita" como todos solían llamarle, partió de este mundo un 24 de Junio en 2020. Nos dejó
                            con un dolor profundo pero también con muchas enseñanzas y momentos gratos. Vive por siempre
                            en nuestros corazones, tía Marujita.
                        </p>
                    </VerticalTimelineElement>

                </VerticalTimeline>
            </Col>
        </Row>

        <Row>
            <Col sm="12" md={{size: 6, offset: 3}}>
                <div style={{textAlign: "center", marginBottom: 25, marginTop: 35}}>
                    <p>Con todo el amor del mundo para mi tía brujita <br/>
                        <a href="https://www.ebi.ac.uk/about/people/emilio-garcia-rios">@gariem</a>
                    </p>
                </div>
            </Col>
        </Row>

    </Container>
);

export default Memorials;
