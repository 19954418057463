import React from "react";
import {Container} from "shards-react";

const MemorialLayout = ({children}) => (
    <Container fluid>
        {children}
    </Container>
);


export default MemorialLayout;
