import React from "react";
import {Redirect} from "react-router-dom";

// Layout Types
import {MemorialLayout, UserLayout} from "./layouts";

// Route Views
import Memorials from "./views/memorials/Memorials";
import MyMemorials from "./views/user/MyMemorials";

export default [
    {
        path: "/",
        exact: true,
        layout: MemorialLayout,
        component: () => <Redirect to="/view/tia-marujita"/>
    },
    {
        path: "/user/memorials",
        layout: UserLayout,
        component: MyMemorials
    },
    {
        path: "/view/:memorialId",
        layout: MemorialLayout,
        component: Memorials
    }
];
