import React from "react";

import MyMemorialsContainer from "../../components/my-memorials";
import {Col, Container, Row} from "shards-react";
import SideBar from "../../components/side-bar";

const MyMemorials = () => (
    <Container>
        <Row>
            <Col lg="3">
                <SideBar />
            </Col>
            <Col lg="9">
                <MyMemorialsContainer/>
            </Col>
        </Row>
    </Container>
);

export default MyMemorials;