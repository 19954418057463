import React from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/extra/styles/shards-dashboards.1.1.0.min.css";

import {Provider} from 'react-redux';
import configureStore from './store/configureStore';

const store = configureStore();

export default () => (
    <Provider store={store}>
        <Router basename={process.env.REACT_APP_BASENAME || ""}>
            <div>
                {routes.map((route, index) => {
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={withTracker(props => {
                                return (
                                    <route.layout {...props}>
                                        <route.component {...props} />
                                    </route.layout>
                                );
                            })}
                        />
                    );
                })}
            </div>
        </Router>
    </Provider>
);
