import React, {Component} from "react";
import {Container, Modal} from "shards-react";
import MemorialList from "./MemorialList";
import BasicDetails from "./BasicDetails";
import './../../assets/custom.css';

const memorials = [
    {
        "id": "60dc975e749ffe088236c235",
        "name": "Maruja Gonzales Gonzales",
        "born": "1951-04-09",
        "passed": "2020-06-23",
        "headline": "Tía Marujita: tú has sido mi Hada Madrina, la de la pomada de la invisibilidad, y por tu Alegría tan firme y constante, indubitable, has logrado convencerme de que, efectivamente, de vez en cuando, este mundo trueca sus lágrimas por risas y contento.",
        "portraitUrl": "https://s3.us-east-2.amazonaws.com/magogo-img/60cfb536139ed24216530bb3/1deef9d0-2fda-4039-85f9-8736c798167f",
        "created": "2021-06-30T17:10:06.977+01:00",
        "bannerUrl": "https://s3.us-east-2.amazonaws.com/magogo-img/60cfb536139ed24216530bb3/74c56565-bcfc-43f7-9b3e-b6382e73a1ff",
        "status": "DRAFT"
    },
    {
        "id": "60dc975f749ffe088236c236",
        "name": "Carlos Garcia Benitez",
        "born": "1951-03-15",
        "passed": "2009-07-31",
        "headline": "A veces los hijos sin saber lo que los padres saben podemos saber lo que ellos quisieran saber. Indudablemente tus sueños alimentan los míos.",
        "portraitUrl": "https://s3.us-east-2.amazonaws.com/magogo-img/60cfb536139ed24216530bb3/471576d1-d26d-47bc-89a3-cefc9aadb842",
        "created": "2021-06-30T17:10:06.977+01:00",
        "bannerUrl": "",
        "status": "DRAFT"
    }
];

class MyMemorialsContainer extends Component {
    render() {

        return (
            <Container>
                <MemorialList memorials={memorials}/>
                <Modal open={true} centered={true} size="lg" backdropClassName="dark-backdrop">
                    <BasicDetails/>
                </Modal>
            </Container>
        );
    }
}

export default MyMemorialsContainer;