import React from "react";
import {Button, Col, Container, ListGroup, ListGroupItem, Row} from "shards-react";
import PropTypes from "prop-types";

const MemorialList = ({memorials}) => (
    <ListGroup>
        <h5>Your Memorials: </h5>
        {memorials.map((memorial, idx) =>
            <ListGroupItem action>
                <Row>
                    <Col>
                        <Container className="text-center">
                            <img style={{position: "relative", width: 100, borderRadius: 9999}}
                                 src={memorial.portraitUrl}/>
                        </Container>
                    </Col>
                    <Col lg={6}>
                        <h5>{memorial.name}</h5>
                        <h6><i>{memorial.born} – {memorial.passed}</i></h6>
                        <h7>{memorial.status}</h7>
                    </Col>
                    <Col>
                        <Button outline>Edit</Button>{' '}
                        <Button outline theme="danger">Delete</Button>
                    </Col>
                </Row>
            </ListGroupItem>
        )}
        <ListGroupItem className="text-center" tag="button" action>
            <i className="material-icons" style={{fontSize: 24}}>add_circle_outline</i>
        </ListGroupItem>

    </ListGroup>
);

MemorialList.propTypes = {
    memorials: PropTypes.array
};

MemorialList.defaultProps = {
    memorials: []
};

export default MemorialList;
