import React, {Component} from "react";
import {
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    FormInput,
    FormTextarea,
    ListGroup,
    ListGroupItem,
    Row
} from "shards-react";
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import "shards-react/components/datepicker/DatePicker.css";

class BasicDetails extends Component {

    constructor(props) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
        this.state = {
            startDate: new Date()
        };
    }

    handleOnChange(val) {
        this.setState({
            startDate: new Date(val)
        });
    }


    render() {
        return (
            <ListGroup>
                <ListGroupItem>
                    <h5><i>Let's create a new memorial:</i></h5>
                </ListGroupItem>
                <ListGroupItem>
                    <Row>
                        <Col sm="4">
                            <Container fluid className="text-center">
                                <img style={{position: "relative", width: 200, borderRadius: 9999}}
                                     src="https://s3.us-east-2.amazonaws.com/magogo-img/60cfb536139ed24216530bb3/1deef9d0-2fda-4039-85f9-8736c798167f"/>
                                <Button pill outline size="sm" className="mb-2" style={{marginTop: 10}}>
                                    <i className="material-icons mr-1">backup</i>
                                    Upload image
                                </Button>
                            </Container>

                        </Col>
                        <Col sm="8">
                            <Form>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <label htmlFor="name">Name:</label>
                                            <FormInput id="name" placeholder="Name"/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <label>Birth date:</label> <br/>
                                            <DatePicker
                                                size="sm"
                                                selected={this.state.startDate}
                                                onChange={this.handleOnChange}
                                                className="form-control"
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <label>Death date:</label> <br/>
                                            <DatePicker
                                                size="sm"
                                                selected={this.state.startDate}
                                                onChange={this.handleOnChange}
                                                className="form-control"
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <label>Headline quote:</label> <br/>
                                            <FormTextarea/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </ListGroupItem>
                <ListGroupItem className="text-right">
                    <Button outline>Continue</Button>{' '}
                    <Button outline theme="danger">Cancel</Button>
                </ListGroupItem>
            </ListGroup>
        );
    }
}

export default BasicDetails;