import React from "react";
import PropTypes from "prop-types";
import {Col, Container, Row} from "shards-react";

import Logo from './magogo-logo.png';

const UserLayout = ({children}) => (
    <Container fluid>
        <Container>
            <Row>
                <Col>
                    <div style={{
                        backgroundImage: `url(${Logo})`,
                        backgroundRepeat: "no-repeat",
                        height: 100,
                        marginTop: 10,
                        marginBottom: 20
                    }}>
                    </div>
                </Col>
            </Row>
        </Container>
        <div>
            {children}
        </div>
    </Container>
);

UserLayout.propTypes = {
    /**
     * Whether to display the navbar, or not.
     */
    noNavbar: PropTypes.bool,
    /**
     * Whether to display the footer, or not.
     */
    noFooter: PropTypes.bool
};

UserLayout.defaultProps = {
    noNavbar: false,
    noFooter: false
};

export default UserLayout;
